@import '@styles';

.contact-view {
    &__main {
        @include spaces(g 12, p 8, mx auto);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-grow: 1;
        width: 100%;
    }

    &__title {
        @include spaces(g 8, p 8, mx auto);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        & > h1 {
            @include spaces(py 16);
            @include fonts(size 20, line 22);
            font-weight: 800;
            text-align: center;

            @include screen(xl) {
                @include fonts(size 26, line 28);
            }
        }

        & > span {
            @include spaces(pt 8);
            @include palette(font light gray-4);
            width: 100%;
            text-align: center;

            @include screen(xl) {
                width: 50%;
            }
        }
    }

    &__cards {
        @include spaces(g 16);
        display: flex;
        flex-direction: column;
        width: 100%;

        @include screen(xl) {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            justify-items: center;
            width: max-content;
        }
    }

    &__card {
        @include palette(bg light accent, border light gray);
        @include spaces(p 12, g 8);
        @include rounded(a 8);
        display: flex;
        align-items: center;
        width: 100%;
        overflow: hidden;
        box-shadow: -1px 25px 25px 0px palette(light, gray-1);

        @include screen(xl) {
            @include spaces(p 12, g 8, lw 190);
        }

        & > i {
            @include palette(font main success);
            @include fonts(size 14);
        }

        & > a {
            text-decoration: none;
            font-weight: 800;
        }

        & > span {
            font-weight: 800;
        }
    }

    &__container {
        @include palette(bg light accent, border light gray);
        @include spaces(p 12, g 12, mt 12, mb 32);
        @include rounded(a 8);
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        overflow: hidden;
        box-shadow: -1px 25px 25px 0px palette(light, gray-1);

        @include screen(xl) {
            @include spaces(mw 400);
        }

        &__content {
            @include spaces(g 8);
            display: flex;
            flex-direction: column;
            width: 100%;

            @include screen(xl) {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
            }
        }

        &__textarea {
            @include spaces(g 8);
            display: flex;
            flex-direction: column;
            width: 100%;
        }

        .button--fill {
            @include palette(font light accent);
            box-shadow: 0 0 0 0;
            font-weight: 700;
        }
    }
}
