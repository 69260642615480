@import '~modern-normalize/modern-normalize.css';
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@300;400;500;600;700;800&display=swap');

/* Normalize definition */
@mixin normalize {
    html {
        font-size: 10px;

        @media only screen and (min-height: 600px) {
            font-size: 12px;
        }

        @media only screen and (min-height: 700px) {
            font-size: 13px;
        }

        @media only screen and (min-height: 800px) {
            font-size: 14px;
        }

        @media only screen and (min-height: 900px) {
            font-size: 15px;
        }

        @media only screen and (min-height: 1000px) {
            font-size: 16px;
        }
    }

    *,
    ::before,
    ::after {
        padding: 0;
        margin: 0;
        border-width: 0;
        background-color: transparent;
        border-style: solid;
        color: inherit;
        font-size: inherit;
        font-weight: inherit;
    }

    body,
    #root,
    #modal,
    #loader,
    #notification {
        display: flex;
        flex-direction: column;
        overflow: hidden;
    }

    body {
        @include fonts(size 8, line 10);
        display: flex;
        flex-direction: column;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-weight: normal;
        inset: 0;
        height: 100%;
        width: 100%;
        overflow-y: auto;
        overflow-x: hidden;
    }

    #root {
        @include palette(bg main base, font main base-dark);
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        overflow: hidden;
    }

    #modal {
        z-index: 101;
    }

    #loader {
        z-index: 102;
    }

    #notification {
        z-index: 103;
    }

    i {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        stroke-width: 1.5;
    }

    input[type='password']::-ms-reveal,
    input[type='password']::-ms-clear {
        display: none;
    }

    /* Custom scrollbar for Application */
    *::-webkit-scrollbar {
        //display: none;
        width: 0.3rem;
    }

    *::-webkit-scrollbar-thumb {
        //display: none;
        @include palette(bg light gray-3);
        border-radius: 2rem;

        &:hover {
            @include palette(bg main gray-4);
        }
    }
}

@mixin dots {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
