@import '@styles';

$apply-textarea-field-roles: primary success warning danger;

// label
@mixin label-roles($label) {
    #{$label} {
        @include palette(font light base-dark);
        //@include palette(font main gray-2);

        @include theme(dark) {
            @include palette(font light gray-3);
        }
    }

    &:focus-within {
        #{$label} {
            @include palette(font light base-dark);
            //@include palette(font main gray-3);

            @include theme(dark) {
                @include palette(font light gray);
            }
        }
    }

    &--#{danger} {
        #{$label} {
            @include palette(font light danger);
            //@include palette(font main danger);

            @include theme(dark) {
                @include palette(font light danger);
            }
        }

        &:focus-within {
            #{$label} {
                @include palette(font light danger);
                //@include palette(font main danger);

                @include theme(dark) {
                    @include palette(font light danger);
                }
            }
        }
    }
}

// content
@mixin content-roles($content) {
    #{$content} {
        @include palette(bg light base, border light gray);

        @include theme(dark) {
            @include palette(bg light base-dark, border light base-dark);
        }

        &-ci,
        &-si,
        &-bi,
        &-ai,
        &-pass {
            @include palette(font light base-dark);
        }

        &-ci:hover,
        &-pass:hover,
        &-select:hover,
        &__modal:hover + .text-field__content-select,
        &-ai--interactive:hover {
            cursor: pointer;

            svg {
                @include palette(bg dark base);
                @include rounded(a 2);
                @include spaces(p 1);
                box-shadow: 0 0 0.25rem palette(dark, base);
                max-height: fit-content;
                //transform: scale(1.3);

                @include theme(dark) {
                    @include palette(bg dark base-dark);
                    box-shadow: 0 0 0.25rem palette(dark, base-dark);
                }
            }
        }
    }

    &:focus-within {
        #{$content} {
            @include palette(border main gray-2);

            @include theme(dark) {
                @include palette(border main gray-3);
            }

            &-ci,
            &-si,
            &-bi,
            &-ai,
            &-pass {
                @include palette(font light base-dark);
                //@include palette(font main gray-3);

                @include theme(dark) {
                    @include palette(font light gray);
                }
            }
        }
    }

    &--#{danger} {
        #{$content} {
            @include palette(border light danger);
            //@include palette(font main danger);

            @include theme(dark) {
                @include palette(border main danger);
            }
        }
    }

    @each $key in $apply-textarea-field-roles {
        &--#{$key} #{$content}--keep {
            @include palette(border main $key);
            //@include palette(border main $key);

            @include theme(dark) {
                @include palette(border main $key);
            }
        }

        &--#{$key}:focus-within {
            #{$content} {
                @include palette(border main $key);

                @include theme(dark) {
                    @include palette(border main $key);
                }

                &-ci,
                &-si,
                &-bi,
                &-ai,
                &-pass {
                    @include palette(font light $key);
                    //@include palette(font dark $key);

                    @include theme(dark) {
                        @include palette(font light $key);
                    }
                }
            }
        }
    }
}

// hint
@mixin hint-roles($hint) {
    #{$hint} {
        @include palette(font light base-dark);
        //@include palette(font main gray-3);

        @include theme(dark) {
            @include palette(font light gray-2);
        }
    }

    &:focus-within {
        #{$hint} {
            @include palette(font main base-dark);

            @include theme(dark) {
                @include palette(font light gray);
            }
        }
    }

    &--#{danger} {
        #{$hint} {
            @include palette(font light danger);
            //@include palette(font main danger);

            @include theme(dark) {
                @include palette(font light danger);
            }
        }
    }

    @each $key in $apply-textarea-field-roles {
        &--#{$key}:focus-within {
            #{$hint} {
                @include palette(font light $key);
                //@include palette(font main $key);

                @include theme(dark) {
                    @include palette(font light $key);
                }
            }
        }
    }
}

.textarea-field {
    $parent: &;
    $label: none;
    $content: none;
    $hint: none;

    align-items: flex-start;
    border-style: none;
    display: flex;
    flex-direction: column;
    position: relative;

    &--fit {
        max-width: fit-content;
    }

    /* &:disabled {
        opacity: 0.6;
    } */

    &__label {
        $label: &;

        @include fonts(line 24);
        cursor: pointer;
        font-weight: 600;
    }

    &__content {
        $content: &;

        @include rounded(a 4);
        @include spaces(b 1);
        border-style: solid;
        display: flex;
        flex-direction: row;
        position: relative;
        width: 100%;
        height: 10rem;

        &--disabled {
            opacity: 0.6;
        }

        &--background {
            background: transparent !important;
            border: none;
            box-shadow: none;
            //width: 45%;

            & > textarea {
                @include spaces(ml 8);
                @include palette(font light base-dark);
                @include fonts(size 24, line 28);
                font-weight: 800;
                background: transparent;
                border: none;
                box-shadow: none;

                &:focus {
                    border: none;
                    box-shadow: none;
                }
            }

            &-bi {
                @include palette(font light base-dark);
                @include fonts(size 24, line 28);
                font-style: normal;
                font-weight: 800;
            }

            &-ai {
                @include palette(font main gray-4);
                display: flex;
                align-items: center;
            }
        }

        

        span {
            @include fonts(line 14);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        textarea,
        span {
            @include fonts(line 14);
            @include palette(font main base-dark);
            @include spaces(p 6);
            background: transparent;
            border-style: none;
            outline: none;
            flex-grow: 1;
            width: 0;

            @include theme(dark) {
                @include palette(font main gray);
            }
        }

        span {
            display: flex;
            align-items: center;
            width: 80%;
            position: absolute;
            @include spaces(ml 18);
            top: 0.25rem;
        }

        textarea::placeholder {
            @include palette(font light gray-3);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        &-ci,
        &-si,
        &-bi,
        &-ai,
        &-pass {
            @include spaces(w 10);
        }

        &-ai--interactive {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        &-ci {
            @include spaces(mr 8);
        }

        &-ci,
        &-bi {
            @include spaces(ml 8);
        }

        &-ai {
            @include spaces(mr 8);
        }

        

        &--hidden {
            display: none;
        }
    }

    &__hint {
        $hint: &;

        @include fonts(size 6.5);
        @include spaces(my 1);
        font-weight: 500;
        z-index: 1;
    }

    &__reserved {
        @include spaces(h 10, mt 1);
    }

    &:disabled {
        #{$label},
        .react-datepicker__input-container > div,
        #{$content}-pass,
        #{$content}__modal {
            cursor: auto;
        }
    }

    // label
    @include label-roles($label);
    // content
    @include content-roles($content);
    // hint
    @include hint-roles($hint);
}
