@import '@styles';

.skeleton-loader {
    $color-1: palette(light, gray);
    $color-2: palette(light, gray-3);
    background: linear-gradient(
        to right,
        $color-1,
        $color-2,
        $color-1,
        $color-2,
        $color-1
    );
    background-size: 500rem 100%;
    background-repeat: repeat-x;
    animation: animate-background 2s linear infinite;

    pointer-events: none;
    user-select: none;
    width: 100%;

    @include theme(dark) {
        $color-1: palette(light, base-dark);
        $color-2: palette(light, gray-3);
        background: linear-gradient(
            to right,
            $color-1,
            $color-2,
            $color-1,
            $color-2,
            $color-1
        );
        background-size: 500rem 100%;
        background-repeat: repeat-x;
        animation: animate-background 2s linear infinite;
    }

    &--box {
        @include rounded(a 8);
        @include spaces(h 23);
    }

    &--square {
        @include rounded(a 8);
        aspect-ratio: 1;
    }

    &--circle {
        aspect-ratio: 1;
        border-radius: 100%;
    }

    &--text {
        @include rounded(a 4);
        @include spaces(h 8);
    }
}

@keyframes animate-background {
    from {
        background-position: 0rem 0%;
    }
    to {
        background-position: 250rem 0%;
    }
}
