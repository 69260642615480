@import '@styles';

.question-view {
    &__main {
        @include spaces(g 16, p 8, pb 32, mb 20, mx auto);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-grow: 1;
        width: 100%;

        @include screen(xl) {
            @include spaces(g 32, p 32);
        }

        & > h1 {
            @include spaces(pb 16);
            @include fonts(size 20, line 22);
            font-weight: 800;
            text-align: center;

            @include screen(xl) {
                @include spaces(py 16);
                @include fonts(size 26, line 28);
            }
        }

        & > h2 {
            @include spaces(py 8);
            @include fonts(size 16, line 18);
            font-weight: 800;
            text-align: center;
            width: 100%;

            @include screen(xl) {
                @include spaces(py 16);
                @include fonts(size 20, line 24);
                width: 60%;
            }
        }
    }

    &__container {
        @include spaces(g 16);
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        @include screen(xl) {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            justify-items: center;
            align-items: baseline;
            width: max-content;
        }

        & > div {
            @include spaces(g 16);
            display: flex;
            flex-direction: column;
            width: 100%;
        }
    }

    &__card {
        @include palette(bg light accent, border light gray);
        @include spaces(py 12, px 16);
        @include rounded(a 8);
        display: flex;
        flex-direction: column;
        width: 100%;
        overflow: hidden;
        box-shadow: -1px 25px 25px 0px palette(light, gray-1);

        @include screen(xl) {
            max-width: 18rem;
            min-width: 30.25rem;
        }

        &__title {
            @include spaces(g 8);
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;

            & > h3 {
                @include fonts(size 9, line 11);
                font-weight: 800;
            }

            & > i {
                @include palette(font light gray-3);
                @include fonts(size 14);
            }
        }

        &__content {
            max-height: 0;
            overflow: hidden;
            transition: all 0.5s cubic-bezier(0, 1, 0, 1);
            width: 100%;
            text-align: justify;

            &--active {
                height: auto;
                max-height: 9999px;
                transition: all 0.5s cubic-bezier(1, 0, 1, 0);
            }

            & > span {
                @include palette(font light gray-4);
                @include spaces(g 2, pt 4);
                display: flex;
                flex-direction: column;

                & > ul {
                    @include spaces(pl 12);
                    list-style: decimal;
                }
            }
        }

        &--active {
            @include palette(border light warning);
            @include spaces(b 1);
        }
    }

    &__information {
        @include spaces(g 16);
        display: flex;
        flex-direction: column;
        width: 100%;

        @include screen(xl) {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            justify-items: center;
            width: max-content;
        }
    }

    &__content {
        @include palette(bg light accent, border light gray);
        @include spaces(p 12, g 8, lw 150);
        @include rounded(a 8);
        display: flex;
        align-items: center;
        width: 100%;
        overflow: hidden;
        box-shadow: -1px 25px 25px 0px palette(light, gray-1);

        & > i {
            @include palette(font main success);
            @include fonts(size 14);
        }

        & > a {
            text-decoration: none;
            font-weight: 800;
        }

        & > span {
            text-decoration: none;
            font-weight: 800;
        }
    }
}
