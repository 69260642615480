@import '@styles';

.mobile-dropdown {
    @include palette(bg light accent, font main base-dark);
    @include spaces(g 8, p 12);
    @include rounded(b 12);
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    &__title {
        @include palette(font light base-dark);
        @include spaces(g 8);
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__logo {
        @include spaces(pl 32);
        display: flex;
        justify-content: center;
        width: 100%;

        & > i {
            & > svg {
                @include spaces(w 70, h 20);
            }
        }
    }

    &__close {
        @include spaces(p 0);
        @include rounded(a 4);

        i {
            & > svg {
                @include fonts(size 20);
            }
        }
    }

    &__content {
        @include spaces(g 12, py 8);
        display: flex;
        flex-direction: column;
        width: 100%;

        & > h3 {
            @include fonts(size 12, line 14);
            text-align: center;
            font-weight: 800;
        }

        & > a {
            display: flex;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            width: 100%;

            & > button {
                width: 100%;
            }
        }
    }

    &__options {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        list-style: none;
        width: 100%;

        & > li {
            width: 100%;
        }
    }

    &__option {
        @include spaces(py 10);
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-decoration: none;
        //border-bottom: 1px solid palette(light, gray-2);

        & > span {
            @include fonts(size 12, line 16);
            font-weight: 700;
            letter-spacing: -2%;
        }

        & > i {
            @include palette(font light gray-3);
            @include fonts(size 10, line 16);
            //@include spaces(w 18, h 18);
        }
    }

    &__select {
        @include palette(bg light base, border light gray, font main base-dark);
        @include spaces(b 1, p 8, g 8);
        @include rounded(a 6);
        display: flex;
        align-items: center;
        width: 100%;
        border-style: solid;

        & > img {
            @include spaces(w 20, h 20);
            border-radius: 50%;
        }

        & > span {
            @include fonts(size 12, line 16);
            font-weight: 700;
        }
    }

    &__divider {
        @include opacity(border 5);
        @include palette(border light gray-4);
        @include spaces(b 0.25, mx auto);
        display: flex;
        border-style: solid;
        width: 100%;

        @include theme(dark) {
            @include palette(border main base);
        }
    }

    &__scan {
        display: flex;
        justify-content: center;
        align-items: center;
        background: palette(light, warning-1) !important;
        border: 0.15rem solid palette(main, warning) !important;
    }

    .button--fill {
        @include spaces(g 4);
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        box-shadow: 0 0 0 0 !important;
    }
}
