@import '@styles';

.about-view {
    &__main {
        @include palette(bg accent 0);
        // @include spaces(g 16, p 8, pb 32, mb 20, mx auto);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        flex-grow: 1;
        width: 100%;

        @include screen(xl) {
            //@include spaces(g 0, p 32);
        }
    }

    &__grid {
        display: flex;
        flex-direction: column;
        width: 100%;

        @include screen(xl) {
            // @include spaces(px 16, py 20, g 8);
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            align-content: center;
            justify-content: center;

        }

        &-bg {
            @include palette(bg accent 0);
        }
    }

    &__row {
        @include spaces(py 20);
        display: flex;
        flex-direction: column;
        width: 100%;

        @include screen(xl) {
            @include spaces(px 20);
            flex-direction: row;

        }

        &-align {
            align-items: center;
        }

        &-bg {
            @include palette(bg primary 500);
        }
    }

    // &__background {
    //     background-image: url('../../assets/images/landscape-1.png');
    //         background-repeat: no-repeat;
    //         background-size: cover;
    //         max-width: 100%;
    //         //height: auto;
    // }

    &__container-fluid {
        --bs-gutter-x: 1.5rem;
        --bs-gutter-y: 0;
        width: 100%;
        padding-right: var(--bs-gutter-x, 0.75rem);
        padding-left: var(--bs-gutter-x, 0.75rem);
        margin-right: auto;
        margin-left: auto;
        align-self: center;
    }

    &__container {
        @include spaces(g 16);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

    &__col-sm-1 {
        flex: 0 0 auto;
        width: 8.33333333%;
    }

    &__col-sm-3 {
        flex: 0 0 auto;
        width: 25%;

        &-align {
            align-self: center;
        }
    }

    &__col-sm-2 {
        flex: 0 0 auto;
        width: 16.66666667%;
    }

    &__col-sm-8 {
        flex: 0 0 auto;
        width: 100%;

        @include screen(lg) {
            width: 66.66666667%;
        }

        &-align {
            align-self: center;
        }
    }

    &__col-sm-10 {
        flex: 0 0 auto;
        width: 100%;

        @include screen(xl) {
            width: 83.33333333%;
        }

        &-align {
            align-self: center;
        }
    }

    &__text-title {
        @include spaces(py 8);
        @include fonts(size 18, line 20);
        // @include fonts(size 14, line 16);
        margin-top: 0;
        margin-bottom: 0.5rem;

        @include screen(xl) {
            @include fonts(size 22, line 24);
        }
    }

    &__text-subtitle {
        @include fonts(size 12, line 16);
        margin-top: 0;
        margin-bottom: 0.5rem;

        @include screen(xl) {
            @include fonts(size 18, line 20);
        }

        // & > strong {
        //     @include palette(font secondary 500);
        // }
    }

    &__text-description {
        @include spaces(g 8);
        display: flex;
        flex-direction: column;

        & > span {
            @include fonts(size 10, line 14);
            margin-top: 0;
            margin-bottom: 0.5rem;
    
            @include screen(xl) {
                @include fonts(size 12, line 16);
            }
        }
    }

    &__card {
        &__container {
           display: flex;
           flex-direction: row;
            justify-content: center;
            width: 100%;
        }

        &__article {
            position: relative;
            //overflow: hidden;

            img {
                //display: block;
                max-width: 100%;
                height: auto;
            }
        }

        &__img {
            //width: 380px;
            border-radius: 1.5rem;
        }

        &__data {
            @include spaces(px 16, py 12);
            @include palette(bg accent 0);
            @include rounded(a 8);
            opacity: 0.8;
            width: 350px;
            box-shadow: 0 8px 24px hsla(0, 0%, 0%, .15);
            position: absolute;
            transform: translate(5%, -25%);
            //margin-inline: auto;

        }
    }

    &__content {
        @include spaces(py 20, g 20, px 8);
        display: flex;
        flex-direction: column;
        width: 100%;
        flex-grow: 1;

        @include screen(xl) {
            @include spaces(px 40);
        }

        &__grid {
            @include spaces(g 8);
            display: flex;
            flex-direction: column;

            @include screen(lg) {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
            }
        }

        &__article {
            @include spaces(pt 45);
            position: relative;
            display: flex;
            flex-direction: column;

            // @include screen(lg) {
            //     @include spaces(pt 0);
            // }

            &>i {
                @include spaces(w 100, h 100);
                //transform: translateY(4.5rem);
                align-self: center;
                position: absolute;
                //bottom: 50%;
                transform: translateY(-7rem);

                @include screen(lg) {
                    transform: translateY(-10rem);
                    @include spaces(w 120, h 120);
                    //position: relative;
                    bottom: unset;
                }

                svg {
                    display: block;
                    max-width: 100%;
                    height: auto;

                }
            }
        }

        &>h1 {
            @include fonts(size 20, line 22);
        }
    }

    &__info {
        @include spaces(px 16, pb 20, pt 25);
        @include palette(bg primary 500, font accent 700);
        @include rounded(a 8);
        box-shadow: 0 8px 24px hsla(0, 0%, 0%, .15);
        min-height: initial;
        height: initial;
        
        & > span {
            @include fonts(size 12, line 14);
            font-weight: 500;

            @include screen(lg) {
                @include fonts(size 10, line 14);
            }
        }


    }

    &__join {
        @include spaces(py 20, g 10);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        flex-grow: 1;

        @include screen(xl) {
            @include spaces(px 40);
        }

        &__row {
            @include spaces(px 8, g 8);
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            
            @include screen(xl) {
                @include spaces(g 4);
                flex-direction: row;
            }
        }

        &__description {
            display: flex;
            align-items: center;
            width: fit-content;

            // @include screen(xl) {
            //     width: auto;
            // }
        }

        &__title {
            @include spaces(pl 0);
            @include palette(font accent 500);
            @include fonts(size 10, line 14);
            text-align: justify;

            @include screen(xl) {
                @include spaces(pl 8);
                @include fonts(size 12, line 16);
            }
        }

        &__subtitle {
            @include spaces(pl 0);
            @include fonts(size 10, line 14);

            @include screen(xl) {
               // @include spaces(pl 4);
                @include fonts(size 12, line 16);
            }
        }

        &__icon {
            @include spaces(p 4, w 25, h 25);
            @include palette(bg secondary 500, font accent 0);
            border-radius: 50%;
            aspect-ratio: 1;
        }
    }
}