.base-layout {
    display: flex;
    flex-grow: 1;
    overflow: hidden;

    &--row {
        flex-direction: row;
    }
    &--col {
        flex-direction: column;
    }
}
