@import '@styles';

.navbar {
    @include palette(bg main accent, font accent 800);
    @include spaces(mx auto, h 35);
    display: flex;
    width: 100%;

    @include screen(xl4) {
        @include spaces(mx auto, h 50);
    }

    &__header {
        @include spaces(mw 600, mx auto, h 35, px 12);
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        text-transform: capitalize;
        width: 100%;

        @include screen(xl2) {
            @include spaces(mw 750);
        }

        @include screen(xl4) {
            @include spaces(mw 820, py 8, px 6, h 50);
        }
    }

    &__logo {
        & > svg {
            @include spaces(w 80, h 16);
        }
    }

    &__options {
        @include spaces(g 0);
        display: flex;
        align-items: center;
        height: 100%;
        width: 100%;
        list-style: none;

        @include screen(xl3) {
            @include spaces(g 6);
        }

        & > li {
            & > a {
                display: flex;
                justify-content: center;
                align-items: center;
                text-decoration: none;
                width: 100%;

                & > button {
                    @include spaces(p 6, g 4);
                    @include fonts(size 9, line 11);
                    display: flex;
                    align-items: center;
                    font-weight: 700;
                    width: 100%;
                }
            }

            .button {
                @include spaces(p 6, g 4);
                @include fonts(size 9, line 11);
                display: flex;
                align-items: center;
                box-shadow: 0 0 0 0;
                font-weight: 700;
            }
        }
    }

    &__option {
        @include palette(font light gray-4);
        @include spaces(g 6, px 2);
        display: flex;
        align-items: center;
        text-decoration: none;
        font-weight: 600;

        & > div {
            background: none;
            position: absolute;
        }

        &:hover {
            @include palette(font main base-dark);

            & > i {
                @include palette(font main base-dark);
            }
        }

        &--active {
            @include palette(font dark base-dark);
            font-weight: 800;

            & > div {
                @include spaces(w 22, h 3);
                @include palette(bg main warning);
                @include rounded(a 8);
                position: absolute;
                bottom: 0.9rem;
                transform: translate(0%, 0%);
            }

            &:hover {
                @include palette(font main base-dark);
            }
        }

        &__select {
            @include spaces(g 4);
            display: flex;
            align-items: center;
            width: 100%;
            border-style: solid;

            .select__content {
                @include spaces(py 0);
                background: none !important;
                border: none;
            }

            .select__content--disabled {
                opacity: 1;
            }

            .select__content .content__select--input {
                width: auto;
            }
        }

        &__scan {
            @include spaces(g 8);
            display: flex;
            justify-content: center;
            align-items: center;
            background: palette(light, warning-1) !important;
            border: 0.15rem solid palette(main, warning) !important;
            box-shadow: 0 0 0 0 !important;

            &--hidden {
                display: none;
            }
        }

        &__soport {
            padding: 0.75rem 1rem !important;
            box-shadow: 0 0 0 0 !important;
        }
    }

    &__profile {
        @include spaces(g 4, p 4);
        position: relative;
        display: flex;

        &__avatar {
            @include spaces(p 2);
            @include palette(bg light warning-1);
            border-radius: 50%;
            position: relative;
        }

        &__arrow {
            @include fonts(size 12);
        }
    }
}
