/* Rounded definition */
$rounded: (
    a: border-radius,
    t: border-top-left-radius border-top-right-radius,
    b: border-bottom-left-radius border-bottom-right-radius,
    l: border-top-left-radius border-bottom-left-radius,
    r: border-top-right-radius border-bottom-right-radius,
    tl: border-top-left-radius,
    tr: border-top-right-radius,
    bl: border-bottom-left-radius,
    br: border-bottom-right-radius,
);

$rounded-by: 0.125rem;
$rounded-base: 0;

@mixin rounded($keys...) {
    @each $key, $value in $keys {
        @if not map-get($rounded, $key) {
            @error "No key #{$key} in rounded";
        }

        @if not $value == full {
            @if $value < 0 {
                @error "No level #{$value} in #{$key}";
            }
        }

        @if $value == full {
            @each $prop in map-get($rounded, $key) {
                #{$prop}: 100%;
            }
        } @else {
            @each $prop in map-get($rounded, $key) {
                #{$prop}: $rounded-base + ($value * $rounded-by);
            }
        }
    }
}
