// Palette colors
$primary: #a51c30;
$primaryAlt: #b24c4c;
$black: #151515;
$gray: #cdd3d5;
$orange: #ce6335;
$orangeAlt: #ef7946;
$blue: #2d2d55;
$blueAlt: #37375e;
$white: #fff;
$green: #2e8b12;
$yellow: #fecb2e;

// field text vars

// border radius
$border-radius-round: 100px;
$border-radius: 7px;

// box shadow
$box-shadow: 0 0 15px -3px $gray;
$box-shadow-red: 0 0 15px -3px rgba($primary, 0.75);

// others
