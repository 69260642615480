@import '@styles';

.top-bar {
    &--background {
        @include palette(bg light accent);
    }

    &__main {
        @include palette(font light base-dark);
        @include spaces(g 8, h 42);
        display: grid;
        grid-template-columns: 1fr 2fr 1fr;
        align-items: center;

        h3 {
            @include fonts(size 12, line 14);
            font-weight: 600;
            cursor: default;
            text-align: center;
        }
    }

    &__left,
    &__right {
        & > i {
            & > svg {
                @include spaces(w 20, h 20);
            }
        }
    }

    &__left {
        @include spaces(ml auto);
    }

    &__right {
        @include spaces(mr auto);
    }

    &__icon {
        & > svg {
            @include spaces(w 70, h 20);
        }
    }
}
