@import '@styles';

.dashboard {
    &__main {
        @include palette(bg accent 50);
        //@include palette(bg light base, font main base-dark);
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        overflow: hidden;
        height: 100vh;
        -webkit-overflow-scrolling: touch;
    }

    &__sidebar {
        display: none;

        @include screen(xl2) {
            display: flex;
        }
    }

    &__mobile-navbar,
    &__mobile-bottombar {
        @include screen(xl2) {
            display: none;
        }
    }

    &__content {
        position: relative;
        width: 100%;

        @include screen(xl2) {
            @include spaces(mh 480);
        }
    }

    &__container {
        @include palette(bg accent 50);
        //@include rounded(t 16);
        position: relative;

        @include screen(xl) {
            @include rounded(t 0);
            background: transparent;
        }

        // &--mobile {
        //     @include spaces(pb 44);
        // }

        // &--tablet {
        //     @include spaces(pb 32);
        // }
    }
}
