.accordion-layout {
    display: flex;
    flex-grow: 1;

    &--top {
        flex-direction: column-reverse;
    }
    &--bottom {
        flex-direction: column;
    }
    &--left {
        flex-direction: row-reverse;
    }
    &--right {
        flex-direction: row;
    }

    &__content {
        display: flex;
    }

    &__accordion {
        flex-grow: 1;
    }
}
