$spaces: (
    p: padding,
    pl: padding-left,
    pr: padding-right,
    px: padding-left padding-right,
    pt: padding-top,
    pb: padding-bottom,
    py: padding-top padding-bottom,
    m: margin,
    ml: margin-left,
    mr: margin-right,
    mx: margin-left margin-right,
    mt: margin-top,
    mb: margin-bottom,
    my: margin-top margin-bottom,
    b: border-width,
    bl: border-left-width,
    br: border-right-width,
    bx: border-left-width border-right-width,
    bt: border-top-width,
    bb: border-bottom-width,
    by: border-top-width border-bottom-width,
    w: width,
    mw: max-width,
    lw: min-width,
    h: height,
    mh: max-height,
    lh: min-height,
    g: gap,
    gx: column-gap,
    gy: row-gap,
);

$spaces-by: 0.125rem;
$spaces-base: 0;

@mixin spaces($keys...) {
    @each $key, $value in $keys {
        @if not map-get($spaces, $key) {
            @error "No key #{$key} in spaces";
        }

        @if not $value == auto {
            @if $value < 0 {
                @error "No level #{$value} in #{$key}";
            }
        }

        @if $value == auto {
            @each $prop in map-get($spaces, $key) {
                #{$prop}: $value;
            }
        } @else {
            @each $prop in map-get($spaces, $key) {
                #{$prop}: $spaces-base + ($value * $spaces-by);
            }
        }
    }
}
