@import '@styles';

$apply-button-roles: primary success warning danger gray-3 secondary accent info;

@mixin button-roles($parent) {
    // roles
    @each $key in $apply-button-roles {
        // variant filled
        &--#{$key}#{$parent}--fill {
            @include opacity(bg 10);
            @include palette(bg main $key, font light secondary);
            //box-shadow: 0 0 15px palette(main, $key);

            @if $key == 'secondary' {
                @include opacity(bg 4, border 10);
                @include palette(font light base-dark, bg light gray, border light gray-3);
            }

            @if $key == 'gray-3' {
                @include palette(bg light gray-1);
            }
            /* @else {
                @include palette(font light $key, bg light $key);
            } */

            /* @include theme(dark) {
                @include palette(bg light $key, font light base);
            } */

            &:hover {
                @include palette(bg light $key);
                //@include opacity(bg 8);

                @if $key == 'secondary' {
                    @include opacity(bg 3);
                    @include palette(bg light gray);
                }

                /* @include theme(dark) {
                    @include palette(bg light $key l 1, font light base l 1);
                } */
            }
            &:active {
                @include palette(bg dark $key /*  d 1, font light base d 1 */);

                /* @include theme(dark) {
                    @include palette(bg light $key d 1, font light base d 1);
                } */
            }
        }

        // variant outlined
        &--#{$key}#{$parent}--outline {
            @include opacity(border 10);
            @include palette(border main $key);

            @include theme(dark) {
                @include palette(border light $key);
            }

            &:hover {
                @include palette(border main $key l 1);

                @include theme(dark) {
                    @include palette(border light $key l 1);
                }
            }
            &:active {
                @include palette(border main $key d 1);

                @include theme(dark) {
                    @include palette(border light $key d 1);
                }
            }
        }

        // variant texted
        &--#{$key}#{$parent}--outline,
        &--#{$key}#{$parent}--text {
            @include palette(font main $key, bg main $key);

            @include theme(dark) {
                @if $key == 'gray-3' {
                    @include palette(font main base, bg main base);
                } @else {
                    @include palette(font light $key, bg light $key);
                }
            }

            &:hover {
                @include opacity(bg 1);
                @include palette(font main $key l 1);

                @include theme(dark) {
                    @if $key == 'gray-3' {
                        @include palette(font main base l 1);
                    } @else {
                        @include palette(font light $key l 1);
                    }
                }
            }
            &:active {
                @include opacity(bg 2);
                @include palette(font main $key d 1);

                @include theme(dark) {
                    @if $key == 'gray-3' {
                        @include palette(font main base d 1);
                    } @else {
                        @include palette(font light $key d 1);
                    }
                }
            }
        }
    }
}

.button {
    $parent: &;

    @include opacity(bg 0, border 0);
    @include palette(bg main base-dark, border accent 50);
    @include spaces(g 4, px 16, py 8, b 1);
    @include fonts(size 9, line 12);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-weight: 500;
    transition: all 0.25s;
    border-style: solid;
    outline: none;

    &--box {
        @include rounded(a 8);
    }

    &--rounded {
        @include rounded(a 16);
    }

    &--circle {
        aspect-ratio: 1;
        border-radius: 100%;
    }

    .badge {
        @include opacity(bg 10, border 10);
    }

    &:active {
        transform: scale(0.95);
    }

    &:disabled {
        opacity: 0.6;
        pointer-events: none;
        user-select: none;
    }

    @include button-roles($parent);
}
