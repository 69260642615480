/* Font size definition */
$fonts: (
    size: font-size,
    line: line-height,
);

$fonts-by: 0.125rem;
$fonts-base: 0;

@mixin fonts($keys...) {
    @each $key, $value in $keys {
        @if not map-get($fonts, $key) {
            @error "No key #{$key} in fonts";
        }

        @if $value < 0 {
            @error "No level #{$value} in #{$key}";
        }

        @each $prop in map-get($fonts, $key) {
            #{$prop}: $fonts-base + ($value * $fonts-by);
        }
    }
}
