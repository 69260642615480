/* Breakpoint definition */
$breakpoints: (
    xs10: 64,
    xs9: 128,
    xs8: 192,
    xs7: 256,
    xs6: 320,
    xs5: 384,
    xs4: 448,
    xs3: 512,
    xs2: 576,
    xs: 640,
    sm: 704,
    md: 768,
    lg: 896,
    xl: 1025,
    xl2: 1152,
    xl3: 1280,
    xl4: 1408,
    xl5: 1536,
    xl6: 1664,
    xl7: 1792,
    xl8: 1920,
    xl9: 2048,
    xl10: 2176,
);

/* screen media query */
@mixin screen($breakpoint) {
    @if not map-get($breakpoints, $breakpoint) {
        @error "No media screen #{$breakpoint}";
    }

    @media only screen and (min-width: "#{map-get($breakpoints, $breakpoint)}px") {
        @content;
    }
}

/* component min width */
@mixin min-width($breakpoint) {
    @if not map-get($breakpoints, $breakpoint) {
        @error "No media screen #{$breakpoint}";
    }

    &[min-width*='-#{map-get($breakpoints, $breakpoint)}-'] {
        @content;
    }
}
