.scroll-layout {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;

    &__content {
        display: flex;
        flex-grow: 1;
        overflow: hidden;

        &--x {
            flex-direction: row;
            overflow-x: auto;
        }
        &--y {
            flex-direction: column;
            overflow-y: auto;
        }
        &--xy {
            flex-direction: column;
            overflow: auto;
        }
    }
}
