@import '@styles';

.step-wallet-view {
    &__main {
       // @include spaces(g 16, p 8, pb 32, mb 20, mx auto);
       @include palette(bg accent 0);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        flex-grow: 1;
        width: 100%;

        @include screen(xl) {
            //@include spaces(g 0, p 32);
        }
    }

    &__header {
        @include spaces(pt 20, pb 12, px 8, g 4);
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        justify-content: center;

        @include screen(xl) {
            @include spaces(pb 0, px 0);
        }

        & > h1 {
            @include spaces(pt 8);
            @include fonts(size 20, line 24);       
            
            @include screen(xl) {
                @include fonts(size 24, line 28);
            }

            & > strong {
                @include palette(font secondary 500);
            }
        }

        & > h2 {
            @include fonts(size 14, line 16);       
    
            @include screen(xl) {
                @include fonts(size 16, line 20);
            }

        }
    }

    &__grid {
        @include palette(bg accent 0);
        display: flex;
        flex-direction: column-reverse;
        width: 100%;

        @include screen(xl) {
            @include spaces(px 16, py 20, g 8);
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            align-content: center;
            justify-content: center;

        }

        &-bg {
            @include palette(bg accent 0);
        }
    }

    &__row {
        display: flex;
        flex-direction: column;
        width: 100%;
        
        @include screen(xl) {
            @include spaces(px 8);
            flex-direction: row;

        }

        &-align {
            align-items: center;
        }
    }

    &__img-fluid {
        @include rounded(a 8);
        margin-bottom: 1rem !important;
        margin-top: 3rem !important;
        max-width: 100%;
        height: auto;

        // &-bg {
        //     @include spaces(b 1);
        //     @include palette(border accent 100);
        //     box-shadow: -1px -1px 19px 0px rgba(165, 162, 162, 0.314);
        //     -webkit-box-shadow: -1px -1px 19px 0px rgba(165, 162, 162, 0.314);;
        //     -moz-box-shadow: -1px -1px 19px 0px rgba(165, 162, 162, 0.314);;
        // }

        &--skeleton {
            margin: auto;
            max-width: 90%;
            height: 400px;
        }
    }

    img {
        vertical-align: middle;
        overflow-clip-margin: content-box;
        overflow: clip;
    }

    &__col-sm-1 {
        flex: 0 0 auto;
        width: 8.33333333%;
    }

    &__col-sm-3 {
        flex: 0 0 auto;
        width: 25%;
    }

    &__col-sm-2 {
        flex: 0 0 auto;
        width: 16.66666667%;
    }

    &__col-sm-8 {
        flex: 0 0 auto;
        width: 66.66666667%;
    }


    &__col-sm-10 {
        flex: 0 0 auto;
        width: 100%;
        
        @include screen(xl) {
            width: 83.33333333%;

        }
    }

    &__container-fluid {
        --bs-gutter-x: 1.5rem;
        --bs-gutter-y: 0;
        width: 100%;
        padding-right: var(--bs-gutter-x, 0.75rem);
        padding-left: var(--bs-gutter-x, 0.75rem);
        margin-right: auto;
        margin-left: auto;
    }

    &__text-title {
        @include spaces(py 8);
        @include palette(font secondary 500);
        @include fonts(size 18, line 20);
        // @include fonts(size 14, line 16);
        margin-top: 0;
        margin-bottom: 0.5rem;

        @include screen(xl) {
        }
    }

    &__text-subtitle {
        @include fonts(size 12, line 16);
        margin-top: 0;
        margin-bottom: 0.5rem;

        @include screen(xl) {
            @include fonts(size 14, line 20);
        }

        & > strong {
            @include palette(font secondary 500);
        }
    }

}