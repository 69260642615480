/* Theme definition */
$themes: (
    light: theme--light,
    dark: theme--dark,
);

@mixin theme($theme) {
    @if not map-get($themes, $theme) {
        @error "No media theme #{$theme}";
    }

    .#{map-get($themes, $theme)} & {
        @content;
    }
}

@mixin dark {
    @media (prefers-color-scheme: dark) {
        @content;
    }
}

@mixin light {
    @media (prefers-color-scheme: light) {
        @content;
    }
}
